import React, { useState, useEffect, useContext } from "react";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound.jsx";
import TableLoader from "../../../../components/Loader/TableLoader.jsx";
import Validations from "../../../../Regex.js";
import toast from "react-hot-toast";
import ScopeTable from "./ScopesTable";
import { MyContext } from "../../../../App.jsx";
import Mandotory from "../../../../components/Input/Mandotory.jsx";
import { isHistoricOptions } from "../Data/data";
import MaxCharacterCount from "../../../../components/Input/MaxCharacterCount.jsx";


import { addScopes, GetAccountsScopesData } from "../Api/Scopes/scopes";
import usePermissions from "../../../../components/CustomHooks/usePermissions.js";
const AddScopes = ({ accountID, getData, setChangesInScopes }) => {
  const [loader, setLoader] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const { hasPermission } = usePermissions();

  const [scopesData, setScopesData] = useState([
    {
      name: "",
      activation_date: null,
      deactivation_date: null,
      is_historic: null,
      invoice_start_date: null,
      invoice_end_date: null,
      notes: null,
    },
  ]);

  const resetScopesData = () => {
    const defaultScope = {
      name: "",
      activation_date: "",
      deactivation_date: "",
      is_historic: "",
      invoice_start_date: null,
      invoice_end_date: null,
      notes: "",
    };

    setScopesData([defaultScope]);
    setFormData([defaultScope]);
    setCharacterCount(0);
  };

  const [formData, setFormData] = useState([...scopesData]);
  const [characterCount, setCharacterCount] = useState(0);
  const maxNotesCharacters = 255;


  const handleChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [field]: value,
    };
  
    if (field === 'notes') {
      setCharacterCount(value.length);
    }
    else if (field === 'activation_date') {
      // Automatically update invoice_start_date based on the new activation_date
      const activationDate = new Date(value);
      const defaultStartDate = new Date(activationDate);
      defaultStartDate.setFullYear(activationDate.getFullYear() - 5);
      newFormData[index].invoice_start_date = defaultStartDate.toISOString().split("T")[0];
    }
  
    setFormData(newFormData);
  };

  const handleScopesSubmission = () => {
    const inputData = formData[0];

    inputData.is_historic =
      inputData.is_historic === "true"
        ? true
        : inputData.is_historic === "false"
          ? false
          : null;

    const mandatoryFields = [
      { name: "Scopes Name", value: inputData.name },
      { name: "Activation Date", value: inputData.activation_date },
      { name: "Invoice Start Date", value: inputData.invoice_start_date },
    ];

    const emptyFields = Validations.checkMandatoryFields(mandatoryFields);

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields[0]?.name} can't be empty`);
    } else {
      if (inputData.deactivation_date === "") {
        inputData.deactivation_date = null;
      }
      addScopes(
        accountID,
        inputData,
        setButtonLoader,
        toast,
        resetScopesData,
        getAccountsScopesAll,
        setChangesInScopes
      );
    }
  };

  const getAccountsScopesAll = async () => {
    try {
      const response = await GetAccountsScopesData(
        setScopesData,
        setLoader,
        accountID
      );
    } catch (error) {
    }
  };

  useEffect(() => {
    getAccountsScopesAll();
  }, [accountID]);

  const [editableRows, setEditableRows] = useState(
    Array(scopesData.length).fill(false)
  );

  const fieldLabels = {
    name: "Name",
    activation_date: "Activation Date",
    deactivation_date: "Deactivation Date",
    is_historic: "Historic",
    invoice_start_date: "Start Date Under Scope (invoice start date)",
    invoice_end_date: "End Date Under Scope (invoice due date)",
    notes: "Notes",
  };

  return (
    <div className="container mt-4 ">
      {hasPermission(['admin:*', 'user:client_config:write:*']) && (
        <div className="bg_shadow bg-light-subtle p-5 mb-5">
          <label className="form-label fw-bold">Add Scopes</label>
          {formData.map((scope, index) => (
            <div key={index} className="mb-4 rounded ">
              <div className="row">
                {Object.keys(scope).map((field, fieldIndex) => (
                  <div key={fieldIndex} className="col-md-6 mb-3">
                    <label>
                      {fieldLabels[field]}{" "}
                      {["activation_date", "name", "invoice_start_date"].includes(field) && (
                        <Mandotory classes="text-red" />
                      )}
                    </label>
                    {field === "activation_date" ||
                      field === "deactivation_date" ||
                      field === "invoice_start_date" ||
                      field === "invoice_end_date" ? (
                      <input
                        type="date"
                        className="form-control "
                        value={scope[field]}
                        onChange={(e) =>
                          handleChange(index, field, e.target.value)
                        }
                      />
                    ) : field === "notes" ? (
                      <div>

                        <textarea
                          className="form-control "
                          value={scope[field]}
                          onChange={(e) =>
                            handleChange(index, field, e.target.value)
                          }
                          maxLength={maxNotesCharacters}
                        />
                        <MaxCharacterCount
                          characterCount={characterCount}
                          maxNotesCharacters={maxNotesCharacters}
                        />
                      </div>
                      
                    ) : field === "name" ? (
                      <select
                        className="form-control form-select"
                        value={scope[field]}
                        onChange={(e) =>
                          handleChange(index, field, e.target.value)
                        }
                      >
                        <option value="">Select Scopes</option>
                        <option value="price_claims">Price Claims</option>
                        <option value="shortages">Shortages</option>
                        <option value="chargebacks">Chargebacks</option>
                        <option value="accruals">Accruals</option>
                      </select>
                    ) : field === "is_historic" ? (
                      <select
                        className="form-control form-select"
                        value={scope[field]}
                        onChange={(e) =>
                          handleChange(index, field, e.target.value)
                        }
                      >
                        <option value="">Select Historic Option</option>
                        {isHistoricOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        value={scope[field]}
                        onChange={(e) =>
                          handleChange(index, field, e.target.value)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {hasPermission(['admin:*', 'user:client_config:write:*']) && (
            <>
              {!buttonloader ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleScopesSubmission}
                >
                  Add Scopes
                </button>
              ) : (
                <button class="btn btn-primary" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status">Loading...</span>
                </button>
              )}
            </>
          )}
        </div>
      )}

      {!loader ? (
        <>
          <div className="container-sm table_main_account border border-secondary">
            {scopesData?.length >= 1 ? (
              <ScopeTable
                getData={getData}
                getAccountsScopesAll={getAccountsScopesAll}
                scopesData={scopesData.map((scope) => ({
                  ...scope,
                  ACTIVATION_DATE: scope.ACTIVATION_DATE
                    ? new Date(scope.ACTIVATION_DATE)
                      .toISOString()
                      .split("T")[0]
                    : null,
                  DEACTIVATION_DATE: scope.DEACTIVATION_DATE
                    ? new Date(scope.DEACTIVATION_DATE)
                      .toISOString()
                      .split("T")[0]
                    : null,
                  INVOICE_START_DATE: scope.INVOICE_START_DATE
                    ? new Date(scope.INVOICE_START_DATE)
                      .toISOString()
                      .split("T")[0]
                    : null,
                  INVOICE_END_DATE: scope.INVOICE_END_DATE
                    ? new Date(scope.INVOICE_END_DATE)
                      .toISOString()
                      .split("T")[0]
                    : null,
                }))}
                setChangesInScopes={setChangesInScopes}
              ></ScopeTable>
            ) : (
              <NoDataFound />
            )}
          </div>
        </>
      ) : (
        <TableLoader />
      )}
    </div>
  );
};

export default AddScopes;
