/**
 * This module processes server change log entries to create a timeline of changes.
 * It formats dates, maps server actions to user-friendly strings, and handles different types of updates.
 * Each log entry is processed and categorized by date, and additional data is included based on the type of action.
 */

import {
  ChangelogDate,
  ChangelogEntry,
  ServerChangeLogEntry,
  WorkPacketActionName,
} from "src/types/work-packets";
import { mapActionNameToActionObject } from "./mapActionNameToPacketActionObject";
import dayjs from "dayjs";

/**
 * Formats a date string into "Today" if the date is the current date, otherwise returns "MMM DD" format.
 * @param dateString - The date string to format.
 * @returns The formatted date string.
 */
function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  } else {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }
}

/**
 * Maps server actions to user-friendly strings.
 */
const mapServerAction: { [key: string]: string } = {
  ASSIGNED: "Assigned the work packet",
  UNASSIGNED: "Unassigned the work packet",
  IDENTIFIED: "Identified the work packet",
  UPDATED: "Changed the",
};

/**
 * Maps update keys to functions that return additional data for changelog entries.
 */
const updateKeyMap: {
  [key: string]: (log: ServerChangeLogEntry) => Partial<ChangelogEntry>;
} = {
  PACKET_OWNER_ID: (log) => ({
    to: log.PACKET_OWNER?.NICKNAME || log.UPDATES.PACKET_OWNER_ID,
  }),
  CURRENT_ACTION: (log) => {
    try {
      return {
        state: "Packet State",
        to: mapActionNameToActionObject(
          log.UPDATES.CURRENT_ACTION as WorkPacketActionName
        ).title,
      };
    } catch (error) {
      console.error("Error in CURRENT_ACTION updateKeyMap:", error, log);
      return {
        state: "Packet State",
        to: log.UPDATES.CURRENT_ACTION,
      };
    }
  },
  NOTES: (log) => ({
    state: "Notes",
    to: log.UPDATES.NOTES,
  }),
  VALID: (log) => ({
    state: "Chargeback Validity",
    to: log.UPDATES.VALID ? "Valid" : "Invalid",
  }),
};

/**
 * Creates a changelog entry with the given log data, time, and additional data.
 * @param log - The server change log entry.
 * @param time - The formatted time of the log entry.
 * @param isSystem - Whether the entry is a system update.
 * @param additionalData - Any additional data to include in the entry.
 * @returns The created changelog entry.
 */
const createEntry = (
  log: ServerChangeLogEntry,
  time: string,
  isSystem: boolean,
  additionalData: Partial<ChangelogEntry> = {}
): ChangelogEntry => {
  const entry: ChangelogEntry = {
    time,
    user: isSystem ? "System auto update" : log.USER?.NICKNAME || "",
    action: mapServerAction[log.ACTION],
    isSystem,
    ...additionalData,
  };

  if (!isSystem && log.USER?.PROFILE_PIC) {
    entry.avatarUrl = log.USER.PROFILE_PIC;
  }

  return entry;
};

/**
 * Handles the updates for a log entry, creating separate changelog entries for each update.
 * @param log - The server change log entry.
 * @param time - The formatted time of the log entry.
 * @param isSystem - Whether the entry is a system update.
 * @returns An array of changelog entries for the updates.
 */
const handleUpdates = (
  log: ServerChangeLogEntry,
  time: string,
  isSystem: boolean
): ChangelogEntry[] => {
  const updateEntries: ChangelogEntry[] = [];
  Object.keys(log.UPDATES).forEach((updateKey) => {
    if (updateKey in updateKeyMap) {
      const additionalData = updateKeyMap[updateKey](log);
      const entry = createEntry(log, time, isSystem, additionalData);
      updateEntries.push(entry);
    }
  });
  return updateEntries;
};

/**
 * Processes a single log entry, creating and categorizing changelog entries by date.
 * @param log - The server change log entry.
 * @param entriesByDate - An object categorizing entries by date.
 */
const processLogEntry = (
  log: ServerChangeLogEntry,
  entriesByDate: { [key: string]: ChangelogEntry[] }
) => {
  const date = formatDate(log.RECORDED_AT);
  const time = new Date(log.RECORDED_AT).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const isSystem = log.USER?.NICKNAME === "systemuser";

  if (log.ACTION === "UPDATED" && Object.keys(log.UPDATES).length === 0) {
    return;
  }

  if (log.ACTION === "UPDATED" && Object.keys(log.UPDATES).length > 0) {
    const updateEntries = handleUpdates(log, time, isSystem);
    entriesByDate[date] = entriesByDate[date] || [];
    entriesByDate[date].push(...updateEntries);
    return;
  }

  const additionalData: Partial<ChangelogEntry> = {};
  if (log.ACTION === "IDENTIFIED") {
    additionalData.packetId = log.WORK_PACKET_ID;
  }

  if (log.ACTION === "ASSIGNED") {
    additionalData.to =
      log.PACKET_OWNER?.NICKNAME || log.UPDATES.PACKET_OWNER_ID;

    if (log.UPDATES.PACKET_OWNER_ID === null) {
      log.ACTION = "UNASSIGNED";
    }
  }

  const entry = createEntry(log, time, isSystem, additionalData);
  entriesByDate[date] = entriesByDate[date] || [];
  entriesByDate[date].push(entry);
};

/**
 * Maps the server change log to an array of changelog dates, each containing changelog entries.
 * @param serverChangeLog - The array of server change log entries.
 * @returns An array of changelog dates.
 */
const mapServerChangeLog = ({
  serverChangeLog,
}: {
  serverChangeLog: ServerChangeLogEntry[];
}): ChangelogDate[] => {
  try {
    const entriesByDate: { [key: string]: ChangelogEntry[] } = {};

    serverChangeLog.forEach((log) => processLogEntry(log, entriesByDate));

    return Object.keys(entriesByDate)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map((date) => ({
        date,
        entries: entriesByDate[date].sort((a, b) => {
          const timeA = dayjs(`1970-01-01 ${a.time}`, "YYYY-MM-DD hh:mm A");
          const timeB = dayjs(`1970-01-01 ${b.time}`, "YYYY-MM-DD hh:mm A");
          return timeB.isBefore(timeA) ? -1 : 1;
        }),
      }));
  } catch (error) {
    console.error("Error in mapServerChangeLog:", error);
    throw error;
  }
};

export default mapServerChangeLog;
