import type { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";

export enum RecoveryStream {
  All = "All",
  InventoryShortages = "Inventory shortages",
  PriceClaims = "Price Claims",
  Accruals = "Accruals",
  Chargebacks = "Chargebacks",
}

export enum WorkPacketActionName {
  FirstDisputeIdentified = "FIRST_IDENTIFIED",
  FirstDisputeFailed = "FIRST_DISPUTE_FAILED",
  FirstDisputeCreated = "FIRST_DISPUTE_CREATED",
  FirstDisputeCreatedInReview = "FIRST_DISPUTE_IN_REVIEW",
  FirstDisputeApproved = "FIRST_DISPUTE_APPROVED",
  FirstDisputePartiallyApproved = "FIRST_DISPUTE_PARTIALLY_APPROVED",
  FirstDisputeDenied = "FIRST_DISPUTE_DENIED",
  FirstDisputePaid = "FIRST_DISPUTE_PAID",
  FirstDisputeInvoiced = "FIRST_DISPUTE_INVOICED",

  SecondDisputeIdentified = "SECOND_IDENTIFIED",
  SecondDisputeFailed = "SECOND_DISPUTE_FAILED",
  SecondDisputeCreated = "SECOND_DISPUTE_CREATED",
  SecondDisputeCreatedInReview = "SECOND_DISPUTE_IN_REVIEW",
  SecondDisputeApproved = "SECOND_DISPUTE_APPROVED",
  SecondDisputePartiallyApproved = "SECOND_DISPUTE_PARTIALLY_APPROVED",
  SecondDisputeDenied = "SECOND_DISPUTE_DENIED",
  SecondDisputePaid = "SECOND_DISPUTE_PAID",
  SecondDisputeInvoiced = "SECOND_DISPUTE_INVOICED",

  Junk = "JUNK",
  Duplicate = "DUPLICATE",
  Expired = "EXPIRED",
  NotApplicable = "NOT_APPLICABLE",
}

export interface WorkPacketAction {
  value: WorkPacketActionName;
  title: string;
  category: string;
  color: string;
  onlyAdminCanSet?: boolean;
}

export enum WorkPacketFilter {
  AsinId = "asinId",
  RecoveryStream = "recoveryStream",
  RecoveryStreamType = "recoveryStreamType",
  RecoveryStreamSubtype1 = "recoveryStreamSubtype1",
  RecoveryStreamSubtype2 = "recoveryStreamSubtype2",
  PacketStage = "packetStage",
  StoreName = "storeName",
  VcPoId = "vcPoId",
  CurrentAction = "currentAction",
  CurrentPacketOwner = "currentPacketOwner",
  VendorName = "vendorName",
  RedFlags = "redFlags",
  IssueId = "issueId",
  ChargebackCreatedAt = "chargebackCreatedAt",
  DisputeCreatedAt = "disputeCreatedAt",

  ManualFilingUser = "manualFilingUser",
}

export interface WorkPacketFilters {
  [WorkPacketFilter.RecoveryStream]: string;
  [WorkPacketFilter.RecoveryStreamType]: string;
  [WorkPacketFilter.RecoveryStreamSubtype1]: string;
  [WorkPacketFilter.RecoveryStreamSubtype2]: string;
  [WorkPacketFilter.PacketStage]: string;
  [WorkPacketFilter.StoreName]: string;
  [WorkPacketFilter.CurrentAction]: string;
  [WorkPacketFilter.CurrentPacketOwner]: string;
  [WorkPacketFilter.VendorName]: string;
  [WorkPacketFilter.RedFlags]: boolean;

  // chargeback packets only:
  [WorkPacketFilter.AsinId]: string[];
  [WorkPacketFilter.VcPoId]: string[];
  [WorkPacketFilter.IssueId]: string[];
  [WorkPacketFilter.ChargebackCreatedAt]: string;

  // shortage packets only:
  [WorkPacketFilter.ManualFilingUser]: string;
  [WorkPacketFilter.DisputeCreatedAt]: string;
}

export enum WorkPacketMetric {
  TotalStores = "Total Stores with Open Work Packets",
  OpenWorkPackets = "Open Work Packets",
  NewPackets = "New Packets",
  AverageTimePacketOpen = "Avg. time that packet is open",
  AverageTimeUnassigned = "Avg. time that packets have been unassigned",
  TotalPacketsAssigned = "Total Packets Assigned",
  PacketsWithRedFlags = "Packets with Red Flags",
  AvgTimePacketOpen = "Avg. time that packet is open",
}

export interface WorkPacketOwner {
  id: string;
  avatar: string;
  title: string;
}

/** Base interface for work packet used in Grid */
export interface WorkPacketBase {
  packetId: string;
  vendorName: string;
  storeName: string;
  currentPacketOwner: WorkPacketOwner;
  currentAction: WorkPacketActionName;
  recoveryStream: RecoveryStream;
  packetStage: string;
  manualFilingUser: string;
  isValidWorkPacket: boolean;
  vendorId: string;
  storeId: number;
  packetDate: string;
  recoveryStreamServer: ServerStream;
  recoveryStreamActivationDate: string;
  vcFillingUser: string;
  notes: string;
  techUser: string;
  disputeByDate: string;
  marketplaceCountry: string;
  modifiedAt: string;
  lastModifiedBy: WorkPacketOwner;
  closedDate: string;
  disputeApprovedAmount: string;
  disputeCreatedAt: string;
  evidenceType: string;
  evidenceAttachment: string;
}

export interface ChargebackWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.Chargebacks;
  vcPoId: string;
  asinId: string;
  chargebackIssueId: string;
  financialCharge: string;
  firstDisputeFinancialCharge: string;
  vendorCode: string;
  chargebackCreateDate: string;
  techUser: string;
  recoveryStreamSubtype: string;
  recoveryStreamSubtype1: string;
  recoveryStreamSubtype2: string;
}

export interface ShortageWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.InventoryShortages;
  disputeId: string;
  disputeAmount: string;
  vcParentInvoiceId: string;
  vcDisputedInvoiceId: string;
  invoiceDate: string;
  invoiceDueDate: string;
  payeeCode: string;
  shortageLag: string;
  disputeResolvedAt: string;
  disputePaymentId: string;
  disputePaymentAmount: string;
  disputePaymentDate: string;
}

export type WorkPacket = ChargebackWorkPacket | ShortageWorkPacket;

export interface ServerChargebackResource {
  ASIN_ID: string;
  CHARGEBACK_ISSUE_ID: string;
  CHARGE_INVOICE_NUMBER: string;
  CREATE_DATE: string;
  FINANCIAL_CHARGE: string;
  ISSUE_SUB_TYPE_DESC: string;
  NOTES: string;
  PRODUCT_NAME: string;
  RECOVERY_STREAM_SUB_TYPE: string;
  REVERSAL_INVOICE: string;
  STATUS: string;
  STORE_NAME: string;
  VC_PO_ID: string;
  VENDOR_CODE: string;
  VENDOR_NAME: string;
}

export interface ServerShortageResource {
  DUE_DATE: string;
  ID: string;
  INVOICE_AMOUNT: `${number}`;
  INVOICE_CREATE_DATE: string;
  INVOICE_DATE: string;
  INVOICE_ID: string;
  INVOICE_STATUS: string;
  PAID_AMOUNT: string | null;
  PAYEE: string;
  SHORTAGE_AMOUNT: string | null;
  STORE_NAME: string;
  VENDOR: string;
}

export type ServerWorkPacketResource = ServerChargebackResource | ServerShortageResource;

/** Server response for work packet */
export interface ServerWorkPacketBase<R extends ServerWorkPacketResource = ServerWorkPacketResource> {
  ACCOUNT: {
    ACTIVATION_DATE: string;
    ID: number;
    SCOPES: unknown[];
    SHORTAGE_LAG: string;
    STORE_NAME: string;
    TECH_USER: string;
    VC_FILING_USER: string | null;
    VENDOR_ID: string;
  };
  CHANGELOGS: ServerChangeLogEntry[];
  ACCOUNT_ID: number;
  STORE_NAME: string;
  CREATED_AT: string;
  CURRENT_ACTION: string;
  NOTES: string;
  PACKET_ID: string;
  PACKET_OWNER: {
    ID: string;
    EMAIL: string;
    FIRST_SEEN_AT: string;
    LAST_SEEN_AT: string;
    NAME: string;
    NICKNAME: string;
    STATUS: string;
    PROFILE_PIC: string;
  };
  PACKET_STAGE: string;
  RESOURCE_ID: string;
  VALID: boolean;
  RECOVERY_STREAM: ServerStream;
  RESOURCE: R;
  WORK_PACKET_PROPERTIES: (ServerWorkPacketProperty | ServerWorkPacketDisputeIdProperty)[];
}

export type ServerWorkPacketPropertyKey =
  | "PARENT_INVOICE_NUMBER"
  | "CHILD_INVOICE_NUMBER"
  | "EVIDENCE_TYPE"
  | "EVIDENCE_ATTACHMENT"
  | "DISPUTE_AMOUNT"
  | "DISPUTE_ID";

export type ServerWorkPacketPropertyType = "string" | "number";

export interface ServerWorkPacketProperty {
  CREATED_AT: string;
  ID: string;
  KEY: ServerWorkPacketPropertyKey;
  TYPE: ServerWorkPacketPropertyType;
  VALUE: string;
  WORK_PACKET_ID: string;
}

export interface ServerAmazonDispute {
  APPROVED_AMOUNT: string;
  DISPUTE_DATE: string;
  DISPUTE_ID: string;
  DISPUTE_REASON: string;
  DISPUTE_STATUS: string;
  DISPUTE_TYPE: string;
  INVOICE_NUMBER: string;
  MARKETPLACE: string;
  STORE_NAME: string;
  TITLE: string;
  TOTAL_DISPUTED_AMOUNT: string;
  VENDOR_NAME: string;
}

export interface ServerPaymentInfo {
  PAID_AMOUNT: string;
  PAYMENT_DATE: string;
  PAYMENT_NUMBER: string;
}

export interface ServerWorkPacketDisputeIdProperty {
  CREATED_AT: string;
  ID: string;
  KEY: "DISPUTE_ID";
  TYPE: "string";
  VALUE: string;
  WORK_PACKET_ID: string;
  AMAZON_DISPUTE: ServerAmazonDispute;
  PAYMENT_INFO: ServerPaymentInfo | null;
}

export type ServerStream =
  | "PQV"
  | "PPV"
  | "PRICE_CLAIMS"
  | "ACCRUALS"
  | "CHARGEBACKS";

export interface ServerChargebackWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: "CHARGEBACKS";
  RESOURCE: ServerChargebackResource;
}

export interface ServerShortageWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: "PQV" | "PPV";
  RESOURCE: ServerShortageResource;
}

export type ServerWorkPacket = ServerChargebackWorkPacket | ServerShortageWorkPacket;

export interface WorkPacketServerResponse<T extends ServerWorkPacket> {
  pages: number;
  total_records: number;
  work_packets: T[];
}

export interface LocalWorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ShortageWorkPacket;
}

export interface ServerWorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ServerChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ServerShortageWorkPacket;
}

/**
 * Represents a unified summary of work packets that can encompass any of the specific packet summary responses.
 */
export interface WorkPacketsSummaryAggregated {
  totalWorkPackets: number;
  totalStores?: number;
  totalRedFlags?: number;
  recoverableAmount?: {
    total: string;
    pending?: string;
    approved?: string;
  };
  workPackets?: {
    action: WorkPacketActionName;
    amount: number;
  }[];
}

export interface ServerUser {
  EMAIL: string;
  FIRST_SEEN_AT: string;
  ID: string;
  LAST_SEEN_AT: string;
  NAME: string;
  NICKNAME: string;
  PROFILE_PIC: string | null;
}

export interface ServerChangeLogEntry {
  ACTION: "ASSIGNED" | "IDENTIFIED" | "UPDATED" | "UNASSIGNED";
  ID: string;
  PACKET_OWNER?: ServerUser;
  RECORDED_AT: string;
  UPDATES: Record<
    "PACKET_OWNER_ID" | "CURRENT_ACTION" | "NOTES" | "VALID",
    string
  >;
  USER?: ServerUser;
  WORK_PACKET_ID: string;
}

export interface ChangelogEntry {
  time: string;
  user: string;
  avatarUrl?: string;
  action: string;
  packetId?: string;
  to?: string;
  state?: string;
  nextAction?: string;
  isSystem?: boolean;
}

export interface ChangelogDate {
  date: string;
  entries: ChangelogEntry[];
}
export interface CommandCenterEntry {
  count: number;
  red_flag_count: number;
  total_amount: string | number;  // server is known to return whole integers as numbers
  expired_count?: number;
  expired_amount?: string | number; // server is known to return whole integers as numbers
  child?: CommandCenterEntryMap;
}

export type CommandCenterEntryMap = Record<string, CommandCenterEntry>;

export interface CommandCenterResponse {
  data: CommandCenterEntryMap;
  message: string;
  status: number;
}

export interface CommandCenterGridRow {
  hierarchy: string[];
  openCount: number;
  openAmount: string;
  expiredCount?: number;
  expiredAmount?: string;
  redFlags: number;
  id: string;
}
