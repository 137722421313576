import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
} from "@mui/material";
import { useGridApiRef, DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { WorkPacketsGridFilters } from "./filters/WorkPacketsGridFilters";
import { useWorkPacketsGridColumns } from "./helpers/getWorkPacketsGridColumns";
import { FilterList, CloudDownloadOutlined } from "@mui/icons-material";
import { adminSettableWorkPacketActions, settableWorkPacketActions } from "./helpers/getStaticFilterOptions";
import { WorkPacketFilter } from "src/types/work-packets";
import { WorkPacketDetailsPopup } from "../WorkPacketDetailsPopup";
import { useWorkPacketsGrid } from "../useWorkPacketsGrid";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import { WorkPacketView } from "../WorkPacketView";
import { BulkUpdatePopup } from "./BulkUpdatePopup";
import usePermissions from "components/CustomHooks/usePermissions";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";

interface WorkPacketsGridProps<T extends WorkPacketType> {
  workPacketType: T;
  onDataUpdate?: () => void;
}

const WorkPacketsGrid = function WorkPacketsGrid<T extends WorkPacketType>({ workPacketType, onDataUpdate }: WorkPacketsGridProps<T>) {
  const apiRef = useGridApiRef();
  const { user } = useAuth0();
  const { isAdmin } = usePermissions();
  const [showFilters, setShowFilters] = useState(true);
  const [currentPacketId, setCurrentPacketId] = useState<string | null>(null);
  const [showBulkUpdatePopup, setShowBulkUpdatePopup] = useState(false);

  const { currentView: view } = useWorkPacketsContext();

  const {
    data,
    paginationModel,
    total,
    loading,
    filters,
    dynamicFilterOptions,
    rowSelection,
    setRowSelection,
    callbacks,
    selectAllEnabled,
    excludeWorkPacketIds,
    triggerFetchWorkPackets,
    rowUpdateSignal,
    handleExport,
    isExporting,
  } = useWorkPacketsGrid(workPacketType);

  useEffect(() => {
    onDataUpdate && onDataUpdate();
  }, [rowUpdateSignal]);

  const packetOwnerOptions = isAdmin
    ? dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner]
    : /* Non-admins can only assign work packets to themselves */
      dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner].filter(
        (owner) => owner.value === user?.sub
      );

  const allowEditPacketActionAndNotes =
    isAdmin || view === WorkPacketView.MyPackets;

  const currentActionOptions = isAdmin ? adminSettableWorkPacketActions : settableWorkPacketActions;
  const columns = useWorkPacketsGridColumns({
    workPacketType,
    ownerOptions: packetOwnerOptions,
    currentActionOptions: currentActionOptions[workPacketType],
    allowEditPacketAction: allowEditPacketActionAndNotes,
    onDetailsClick: (id: string) => setCurrentPacketId(id),
  });

  const { columnVisibilityModel, handleColumnVisibilityChange } =
    useColumnVisibility("work-packets-grid");

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <>
      <Box bgcolor="#fff" p={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Box display="flex" gap="12px">
            <Button
              startIcon={<FilterList />}
              variant="outlined"
              onClick={toggleFilters}
              className={showFilters ? "" : "active"}
            >
              Filters
            </Button>
          </Box>

          <Box display="flex" gap="12px">
            <Button
              variant="outlined"
              disabled={rowSelection.length === 0}
              onClick={() => {
                console.log("Current selection", { rowSelection });
                setShowBulkUpdatePopup(true);
              }}
            >
              Bulk Actions
            </Button>
            {isAdmin && (
              <IconButton
                className="outlined"
                onClick={handleExport}
                disabled={loading || isExporting}
              >
                <CloudDownloadOutlined />

                {isExporting && (
                  <CircularProgress
                    size={14}
                    style={{ marginLeft: "12px", color: "#101828" }}
                  />
                )}
              </IconButton>
            )}
          </Box>
        </Box>

        <Collapse in={showFilters}>
          <WorkPacketsGridFilters
            loading={loading}
            dynamicFilterOptions={dynamicFilterOptions}
            view={view}
          />
        </Collapse>

        <Box>
          <DataGridPro
            {...callbacks}
            apiRef={apiRef}
            rows={data}
            columns={columns}
            getRowId={(row) => row.packetId}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={total}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            filterMode="server"
            sortingMode="server"
            rowSelectionModel={rowSelection}
            keepNonExistentRowsSelected
            checkboxSelection
            disableRowSelectionOnClick
            loading={loading}
            rowHeight={70}
            autoHeight
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
          />
        </Box>
      </Box>

      {currentPacketId != null && (
        <WorkPacketDetailsPopup
          view={view}
          workPacketId={currentPacketId}
          open
          onClose={() => setCurrentPacketId(null)}
          onSuccessfulUpdate={() => {
            triggerFetchWorkPackets();

            // Trigger summary data re-fetch
            onDataUpdate && onDataUpdate();
          }}
        />
      )}

      {showBulkUpdatePopup && (
        <BulkUpdatePopup
          open={showBulkUpdatePopup}
          onClose={(success?: boolean) => {
            setShowBulkUpdatePopup(false);

            // Refetch data if the bulk update was successful
            if (success) {
              triggerFetchWorkPackets();

              // Reset selection
              setRowSelection([]);

              // Trigger summary data re-fetch
              onDataUpdate && onDataUpdate();
            }
          }}
          workPacketIds={rowSelection as string[]}
          filters={filters}
          selectedAll={selectAllEnabled}
          excludeIds={excludeWorkPacketIds}
          currentOwnerOptions={packetOwnerOptions}
          allowEditPacketActionAndNotes={allowEditPacketActionAndNotes}
        />
      )}
    </>
  );
};

export default WorkPacketsGrid;
