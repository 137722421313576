import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  Divider,
  Skeleton,
  Collapse,
  IconButton,
} from "@mui/material";
import { FC, useState } from "react";
import { actionNameToActionObjectMap, mapActionNameToActionObject } from "src/pages/UserDashboard/WorkPackets/mappers/mapActionNameToPacketActionObject";
import { WorkPacketActionName } from "src/types/work-packets";

interface SummaryByWorkPacketActionProps {
  total: number;
  actions: {
    action: WorkPacketActionName;
    amount: number;
  }[];
  loading?: boolean;
}

export const SummaryByWorkPacketActionCard: FC<
  SummaryByWorkPacketActionProps
> = ({ total, actions, loading }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  // Get the order of actions from the mapping
  const actionOrder = Object.keys(
    actionNameToActionObjectMap
  ) as WorkPacketActionName[];

  const sortedActions = actions.sort((a, b) => {
    return actionOrder.indexOf(a.action) - actionOrder.indexOf(b.action);
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
      paddingX={3}
      paddingY={3}
      borderRadius={2}
      border="1px solid #D0D5DD"
      sx={{ width: { xs: "100%", md: "55%" } }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Packets in Different ‘Current Action’ Statuses </Typography>
        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: "30px", flex: 0.5 }} />
        ) : (
          <Typography variant="h2">{total}</Typography>
        )}
      </Box>

      {loading && (
        <Box mt={2}>
          <Divider />
          <Box display="flex" flexDirection="column" gap={2} pt={2}>
            <Skeleton variant="text" sx={{ fontSize: "24px", flex: 1 }} />
            <Skeleton variant="text" sx={{ fontSize: "24px", flex: 1 }} />
          </Box>
        </Box>
      )}

      {!loading && actions.length > 0 && (
        <Box mt={2}>
          <Divider />

          <Box display="flex" flexDirection="column" gap={2} pt={2}>
            {sortedActions.slice(0, 3).map((action) => (
              <DataRow
                key={action.action}
                title={action.action}
                value={action.amount}
              />
            ))}
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" gap={2} pt={2}>
              {sortedActions.slice(3).map((action) => (
                <DataRow
                  key={action.action}
                  title={action.action}
                  value={action.amount}
                />
              ))}
            </Box>
          </Collapse>
          {sortedActions.length > 3 && (
            <Box display="flex" justifyContent="center">
              <IconButton onClick={handleToggleExpand}>
                {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const DataRow = ({
  title,
  value,
}: {
  title: WorkPacketActionName;
  value: number;
}) => {
  const actionObject = mapActionNameToActionObject(title);
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box
        width="12px"
        height="12px"
        borderRadius="2px"
        sx={{ backgroundColor: actionObject.color }}
      />
      <Typography color="secondary.light" ml={1}>
        {actionObject.title}
      </Typography>
      <Typography variant="h3" pl={3} ml="auto">
        {value}
      </Typography>
    </Box>
  );
};
