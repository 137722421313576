import type { LogoutOptions } from "@auth0/auth0-react";
import Axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse, type InternalAxiosRequestConfig } from "axios";
import { toast } from "react-hot-toast";
import ApiURL from "./config/config";

export interface HttpRequestConfig<Data> extends AxiosRequestConfig<Data> {
  errorMessage?: string;
}

const showErrorToast = (message: string) => toast.error(message, { className: "text-center", duration: 8000 });
const showUnknownErrorToast = () => showErrorToast("An error occurred.");
const showErrorToastAndThrow = (message?: string) => (error: any): any => {
  if (message) {
    showErrorToast(message);
    console.error("[http] " + message);
  }
  throw error;
};

class HttpModule implements Pick<AxiosInstance, "get" | "post" | "put" | "patch" | "delete"> {
  axios: AxiosInstance;
  tokenGenerator?: () => Promise<string>;
  logoutAuth0?: (options?: LogoutOptions) => Promise<void>;

  setTokenGenerator = (generator: () => Promise<string>) => {
    this.tokenGenerator = generator;
  }

  unsetTokenGenerator = () => {
    this.tokenGenerator = undefined;
  }

  setLogout = (logout: any) => {
    this.logoutAuth0 = logout;
  }

  constructor() {
    this.axios = Axios.create({ adapter: "fetch", baseURL: ApiURL, headers: { "Content-type": "application/json" }});

    this.axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      const token = this.tokenGenerator ? await this.tokenGenerator() : undefined;

      if (token) {
        config.url?.includes("/ShortagesDispute") && config.method === "post" ? config.headers["Content-Type"] = 'multipart/form-data' : null
        config.url?.includes("/MDT/") && config.method === "post" ? config.headers["Content-Type"] = 'multipart/form-data' : null
        config.headers["Authorization"] = "Bearer " + token;
      }

      if ((config.url === "/OSSR/" || config.url!.includes("OSSR_new") || config.url?.includes("/InvoiceAuto/")) && config.method === "get") {
        config.maxContentLength = Infinity;
        config.maxBodyLength = Infinity;
        config.responseType = 'blob';
      }

      return config;
    });

    this.axios.interceptors.response.use(response => response, error => {
      if (!error) {
        console.error("Axios response errored with empty error.");
        showErrorToast("An error occured");
        throw error;
      }

      if (Axios.isCancel(error)) return;
      if (!Axios.isAxiosError(error)) throw error;

      if (!error.response) {
        if (error.code === "ERR_NETWORK") {
          if (!window.navigator.onLine) {
            showErrorToast("You are offline. Please check your connection and try again.");
          } else {
            showErrorToast("A network issue has occured.");
          }
        } else {
          showUnknownErrorToast();
        }
        throw error;
      }

      if (error.response.status === 401) {
        showErrorToast("Your session has expired. Redirecting to login page...");
        if (this.logoutAuth0) this.logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
        throw error;
      }

      let errorMessage: string | undefined;
      if (error.response.data && typeof error.response.data === "object") {
        const responseJson = error.response.data;
        if (typeof responseJson.data?.response_message === "string") {
          errorMessage = `Error from third-party service: ${responseJson.data.response_message}`;
        } else if (typeof responseJson.message === "string") {
          errorMessage = responseJson.message;
        }
      } else if (error.response.status === 500) {
        errorMessage = "An error occurred on the server.";
      } else if (error.response.status === 406 && error.response.config.responseType === "blob") {
        errorMessage = "Account Vendor Not Found";
      } else if (error.response.status === 400) {
        errorMessage = "An error occurred, your action might be invalid.";
      }

      if (errorMessage) {
        showErrorToast(errorMessage);
      } else {
        showUnknownErrorToast();
      }

      throw error;
    });
  }
  get<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: HttpRequestConfig<D>): Promise<R> {
    return this.axios.get(url, config).catch(showErrorToastAndThrow(config?.errorMessage));
  }
  post<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: HttpRequestConfig<D>): Promise<R> {
    return this.axios.post(url, data, config).catch(showErrorToastAndThrow(config?.errorMessage));
  }
  put<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: HttpRequestConfig<D>): Promise<R> {
    return this.axios.put(url, data, config).catch(showErrorToastAndThrow(config?.errorMessage));
  }
  patch<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: HttpRequestConfig<D>): Promise<R> {
    return this.axios.patch(url, data, config).catch(showErrorToastAndThrow(config?.errorMessage));
  }
  delete<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: HttpRequestConfig<D>): Promise<R> {
    return this.axios.delete(url, config).catch(showErrorToastAndThrow(config?.errorMessage));
  }
}

export const http = new HttpModule();
