import dayjs from "dayjs";
import {
  type ChargebackWorkPacket,
  type LocalWorkPacketTypeMap,
  RecoveryStream,
  type ServerChangeLogEntry,
  type ServerChargebackWorkPacket,
  type ServerShortageWorkPacket,
  type ServerUser,
  type ServerWorkPacket,
  ServerWorkPacketDisputeIdProperty,
  type ServerWorkPacketTypeMap,
  type ShortageWorkPacket,
  WorkPacketActionName,
  type WorkPacketBase,
} from "src/types/work-packets";
import { attempt } from "src/utils/attempt";
import { WorkPacketType } from "../WorkPacketType";

const marketplaceCountryRegex = /(.+) - .+/;
const getMarketplaceCountry = (packet: ServerWorkPacket) =>
  marketplaceCountryRegex.exec(packet.ACCOUNT.STORE_NAME)?.[1] ?? "Unknown";

const mapPacketOwner = (owner?: ServerUser) =>
  owner
    ? {
        id: owner.ID,
        avatar: owner.PROFILE_PIC ?? "",
        title: owner.NICKNAME,
      }
    : {
        id: "",
        avatar: "",
        title: "Unassigned",
      };

/**
 * There are properties that are common to all work packets, but that are computed differently for each type.
 * This type represents all the work packet properties that are computed way the same across all work packet types
 * (by omitting those common types that are computed differently).
 *
 * TODO (daniel): this is internal, but should probably figure out a better name than this
 */
type WorkPacketBaseBase = Omit<
  WorkPacketBase,
  "recoveryStream" | "vendorName" | "disputeCreatedAt" | "disputeByDate" | "disputeApprovedAmount"
>;

/** A work packet's dispute is considered resolved if any of its changelog entries is one of these actions.  */
const disputeResolvingActions = ["DISPUTE_DENIED", "DISPUTE_APPROVED", "DISPUTE_PARTIAL_APPROVED"];

const mapServerResponseToWorkPacketBase = (packet: ServerWorkPacket): WorkPacketBaseBase => {
  const latestChangelogEntry: ServerChangeLogEntry | undefined  = packet.CHANGELOGS[0];
  const closedAt = packet.CHANGELOGS.find(entry => entry.UPDATES.CURRENT_ACTION === "DISPUTE_INVOICED")?.RECORDED_AT;

  const evidenceType = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_TYPE")?.VALUE ?? "";
  const evidenceAttachment = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_ATTACHMENT")?.VALUE ?? "";

  return {
    packetId: packet.PACKET_ID,
    packetStage: packet.PACKET_STAGE,
    storeName: packet.ACCOUNT.STORE_NAME,
    currentPacketOwner: mapPacketOwner(packet.PACKET_OWNER),
    currentAction: packet.CURRENT_ACTION as WorkPacketActionName,
    manualFilingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    techUser: packet.ACCOUNT.TECH_USER,
    vendorId: packet.ACCOUNT.VENDOR_ID,
    storeId: packet.ACCOUNT.ID,
    packetDate: formatDate(packet.CREATED_AT),
    recoveryStreamServer: packet.RECOVERY_STREAM,
    recoveryStreamActivationDate: formatDate(packet.ACCOUNT.ACTIVATION_DATE),
    vcFillingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    notes: packet.NOTES,
    isValidWorkPacket: packet.VALID,
    marketplaceCountry: getMarketplaceCountry(packet),
    modifiedAt: latestChangelogEntry ? formatDate(latestChangelogEntry.RECORDED_AT) : "",
    lastModifiedBy: mapPacketOwner(latestChangelogEntry?.USER),
    closedDate: closedAt ? formatDate(closedAt) : "",
    evidenceType,
    evidenceAttachment,
  };
};

export const mapServerResponseToChargebackWorkPacket = (packet: ServerChargebackWorkPacket): ChargebackWorkPacket => {
  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;

  const disputeCreatedAt = packet.CHANGELOGS.find(
    entry =>
      entry.UPDATES.CURRENT_ACTION === WorkPacketActionName.FirstDisputeCreated ||
      entry.UPDATES.CURRENT_ACTION === WorkPacketActionName.SecondDisputeCreated,
  )?.RECORDED_AT;

  return ({
    ...mapServerResponseToWorkPacketBase(packet),
    recoveryStream: RecoveryStream.Chargebacks,
    vendorName: packet.RESOURCE.VENDOR_NAME,
    disputeByDate: attempt(
      () => dayjs(packet.RESOURCE.CREATE_DATE).add(30, "days").format("YYYY-MM-DD"),
      "",
      "Error calculating chargeback packet dispute by date"
    ),
    disputeCreatedAt: disputeCreatedAt ? formatDate(disputeCreatedAt) : "",
    recoveryStreamSubtype: packet.RESOURCE.RECOVERY_STREAM_SUB_TYPE,
    vcPoId: packet.RESOURCE.VC_PO_ID,
    asinId: packet.RESOURCE.ASIN_ID,
    chargebackIssueId: packet.RESOURCE.CHARGEBACK_ISSUE_ID,
    recoveryStreamSubtype1: packet.RESOURCE.ISSUE_SUB_TYPE_DESC,
    recoveryStreamSubtype2: packet.RESOURCE.NOTES,
    financialCharge: packet.RESOURCE.FINANCIAL_CHARGE,
    chargebackCreateDate: formatDate(packet.RESOURCE.CREATE_DATE),
    firstDisputeFinancialCharge: packet.RESOURCE.FINANCIAL_CHARGE,
    vendorCode: packet.RESOURCE.VENDOR_CODE,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
  });
};

const parentInvoiceIdFromChildInvoiceIdRegex = /(.*?)(SCR)*/;
const getVcParentInvoiceId = (packet: ServerShortageWorkPacket): string | undefined => {
  const parentInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(
    property => property.KEY === "PARENT_INVOICE_NUMBER",
  );
  if (parentInvoiceProperty) return parentInvoiceProperty.VALUE;

  const childInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "CHILD_INVOICE_NUMBER");
  if (childInvoiceProperty) return parentInvoiceIdFromChildInvoiceIdRegex.exec(childInvoiceProperty.VALUE)?.[1];
};

export const mapServerResponseToShortageWorkPacket = (packet: ServerShortageWorkPacket): ShortageWorkPacket => {
  const disputeResolvedAt = packet.CHANGELOGS.find(entry =>
    disputeResolvingActions.includes(entry.UPDATES.CURRENT_ACTION),
  )?.RECORDED_AT;

  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;
  const paymentInfo = disputeIdProperty?.PAYMENT_INFO;

  const disputeAmountProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "DISPUTE_AMOUNT");

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    disputeByDate: formatDate(packet.RESOURCE.DUE_DATE),
    recoveryStream: RecoveryStream.InventoryShortages,
    vendorName: packet.RESOURCE.VENDOR,
    disputeId: disputeIdProperty?.VALUE ?? "",
    disputeAmount: disputeAmountProperty?.VALUE ?? "",
    disputeCreatedAt: disputeIdProperty?.CREATED_AT ? formatDate(disputeIdProperty?.CREATED_AT) : "",
    invoiceDate: formatDate(packet.RESOURCE.INVOICE_DATE),
    invoiceDueDate: formatDate(packet.RESOURCE.DUE_DATE),
    vcParentInvoiceId: packet.RESOURCE.INVOICE_ID,
    vcDisputedInvoiceId: getVcParentInvoiceId(packet) ?? "",
    payeeCode: packet.RESOURCE.PAYEE,
    shortageLag: packet.ACCOUNT.SHORTAGE_LAG,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
    disputeResolvedAt: disputeResolvedAt ?? "",
    disputePaymentId: paymentInfo?.PAYMENT_NUMBER ?? "",
    disputePaymentAmount: paymentInfo?.PAID_AMOUNT ?? "",
    disputePaymentDate: paymentInfo?.PAYMENT_DATE ? formatDate(paymentInfo.PAYMENT_DATE) : "",
  };
};

export function mapServerToLocalWorkPackets<T extends WorkPacketType>(
  workPacketType: T,
  serverItems: ServerWorkPacketTypeMap[T][],
): LocalWorkPacketTypeMap[T][];

export function mapServerToLocalWorkPackets(workPacketType: WorkPacketType, serverItems: ServerWorkPacket[]) {
  if (workPacketType === WorkPacketType.CHARGEBACKS) {
    return (serverItems as ServerChargebackWorkPacket[]).map(mapServerResponseToChargebackWorkPacket);
  }
  if (workPacketType === WorkPacketType.SHORTAGES) {
    return (serverItems as ServerShortageWorkPacket[]).map(mapServerResponseToShortageWorkPacket);
  }
}

const formatDate = (date: string): string => attempt(() => dayjs(date).format("YYYY-MM-DD"), "", "[formatDate]");
