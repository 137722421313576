import { Box } from "@mui/material";
import { Field } from "formik";
import {
  AutocompleteField,
  CheckboxField,
  MultiAutocompleteField,
  SelectField,
  StringDateRangePickerField,
} from "src/components/UI/Form";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../WorkPacketView";
import { staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { type FiltersForm } from "./WorkPacketsGridFilters";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useQuery } from "@tanstack/react-query";
import { getVendorList } from "src/queries/vendors";
import { useMemo } from "react";

const EMPTY_ARRAY: string[] = [];

export const ChargebackFiltersForm: FiltersForm = ({
  props: { dynamicFilterOptions, userNickname, view },
  formApi: { setFieldValue },
}) => {
  const { data: vendorList } = useQuery({
    queryKey: ["vendors"],
    queryFn: getVendorList,
    staleTime: 30000,
  });

  const vendorOptions = useMemo(
    () => (vendorList ?? []).map(vendor => ({ value: vendor.VENDOR_NAME, title: vendor.VENDOR_NAME })),
    [vendorList],
  );

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={dynamicFilterOptions[WorkPacketFilter.StoreName]}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.PacketStage}
        component={SelectField}
        label="Packet Stage"
        options={staticFilterOptions[WorkPacketFilter.PacketStage]}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(view, userNickname)}
        options={dynamicFilterOptions[WorkPacketFilter.CurrentPacketOwner]}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={view !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={staticFilterOptions[WorkPacketFilter.CurrentAction]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title.replaceAll("Dispute Identified", "Chargeback Identified")}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamType}
        component={AutocompleteField}
        label="Recovery Stream Type"
        options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamType]}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Type"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype1}
        component={AutocompleteField}
        label="Recovery Stream Subtype 1"
        options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamSubtype1]}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 1"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype2}
        component={AutocompleteField}
        label="Recovery Stream Subtype 2"
        options={dynamicFilterOptions[WorkPacketFilter.RecoveryStreamSubtype2]}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 2"
        setFieldValue={setFieldValue}
      />

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.IssueId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          getOptionLabel={(option: any) => option.title}
          label="Chargeback Issue ID"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }} display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.ChargebackCreatedAt}
            component={StringDateRangePickerField}
            label="Chargeback Created At"
          />
        </Box>
        <Field type="checkbox" name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.VcPoId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="VC PO ID"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.AsinId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Filter by ASIN"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>
    </Box>
  );
};
