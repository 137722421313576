import { Autocomplete, TextField } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";

interface Option {
  title: string;
  avatar?: string;
  color?: string;
  value?: string;
}

interface CellProps extends GridRenderEditCellParams {
  options: Option[];
  groupBy?: (option: any) => string;
}

const isArrayOfString = (array: any[]): array is string[] => typeof array[0] === "string";
const unwrapValue = (value: Option | string) => typeof value === "string" ? value : (value.value ?? value.title);

const computeInitialInputValue = (value: string | Option, options: string[] | Option[]) => (): string => {
  if (typeof value === "string") {
    if (isArrayOfString(options)) return value;
    return options.find(opt => opt.value === value || opt.title === value)?.title ?? "";
  } else {
    return value.title;
  }
}

const EditableAutocompleteCell: FC<CellProps> = ({
  id,
  value,
  field,
  api,
  options,
  groupBy,
}) => {
  const [inputValue, setInputValue] = useState<string>(computeInitialInputValue(value, options));

  const handleChange = (_event: SyntheticEvent, newValue: Option) => {
    setInputValue(newValue.title);
    if (typeof value === "string") {
      api.setEditCellValue({ id, field, value: newValue.value ?? newValue.title });
    } else {
      api.setEditCellValue({ id, field, value: newValue });
    }
  };

  const stringValue = unwrapValue(value);

  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      value={options.find(option => option.value === stringValue || option.title === stringValue)}
      onChange={handleChange}
      options={options}
      getOptionLabel={option => option.title}
      renderInput={params => <TextField {...params} variant="outlined" />}
      disableClearable
      isOptionEqualToValue={(option, newValue) => option.value === newValue.value || option.title === newValue.title}
      size="small"
      className="autocomplete-cell-container"
      renderOption={(props, option) => (
        <li
          {...props}
          className={`autocomplete-menu-item ${props.className}`}
          key={option.value ? option.value : option.title}
        >
          {option?.color && (
            <span
              className="autocomplete-menu-item__color-indicator"
              style={{ background: option.color }}
            />
          )}

          {option.avatar && (
            <img
              src={option.avatar}
              alt={option.title}
              className="autocomplete-menu-item__avatar"
            />
          )}

          <span className="autocomplete-menu-item__text">{option.title}</span>
        </li>
      )}
      groupBy={groupBy}
    />
  );
};

export default EditableAutocompleteCell;
