import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { FieldProps } from "formik";
import { mapActionNameToActionObject } from "src/pages/UserDashboard/WorkPackets/mappers";

interface AutocompleteFieldProps extends FieldProps {
  id: string;
  label: string;
  options: any[];
  groupBy?: (option: any) => string;
  getOptionLabel: (option: any) => string;
  placeholder: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  disableClearable?: boolean;
}

/**
 * AutocompleteField component integrates Material-UI's Autocomplete with Formik.
 */
const AutocompleteField: FC<AutocompleteFieldProps> = ({
  id,
  label,
  options,
  groupBy,
  getOptionLabel,
  placeholder,
  setFieldValue,
  field,
  disabled,
  onChange,
  disableClearable,
}) => {
  const [inputValue, setInputValue] = useState(field.value || "");

  // Sync input value with Formik's field value
  useEffect(() => {
    const option = options.find(option => option.value === field.value);
    setInputValue(option ? option.title ?? option.name : field.value ? String(field.value) : "");
  }, [field.value, options]);

  // hack for "current action" autocomplete:
  // include the current value in the options if the current value is not empty it's not already there
  const allOptions = useMemo(() => {
    if (!field.value) return options;
    if (!options.some(option => option.value === field.value)) {
      return [...options, mapActionNameToActionObject(field.value)].sort((a, b) => a.category.localeCompare(b.category));
    } else {
      return options;
    }
  }, [field.value, options]);

  const groupedOptions = useMemo(
    () =>
      groupBy ? groupAutocompleteOptions(allOptions) : allOptions,
    [allOptions, groupBy],
  );

  return (
    <Autocomplete
      id={id}
      options={groupedOptions}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      size="small"
      value={allOptions.find(option => option.value === field.value) ?? null}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        // HACK: the only time input changes without user interaction is when the filters are cleared,
        // but newInputValue tends to still hold to the previous value. short-circuit to empty string in this case.
        if (!event) return void setInputValue("");
        return setInputValue(newInputValue);
      }}
      renderInput={params => <TextField {...params} placeholder={placeholder} variant="outlined" label={label} />}
      onChange={(_, valueObject) => {
        setFieldValue(field.name, valueObject ? String(valueObject.value) : "");
        if (onChange) {
          onChange(valueObject);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} className={`autocomplete-menu-item ${props.className}`} key={props.key}>
          {option?.color && (
            <span className="autocomplete-menu-item__color-indicator" style={{ background: option.color }} />
          )}
          {option.avatar && <img src={option.avatar} alt={option.title} className="autocomplete-menu-item__avatar" />}
          <span className="autocomplete-menu-item__text">
            {getOptionLabel?.(option) ?? option.title ?? option.name}
          </span>
        </li>
      )}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
};

const groupAutocompleteOptions = (options: any[]) => {
  return options.map((option) => {
    const firstLetter = option.category[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

export default AutocompleteField;
