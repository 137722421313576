import debounce from "lodash.debounce";
import { type FC, useCallback, useEffect, useState } from "react";
import * as WorkPacketsAPI from "../api/workPacketsAPI";
import { Box, Divider, Typography } from "@mui/material";
import { type GridRenderCellParams, type GridColDef } from "@mui/x-data-grid";
import { type DataGridProProps, DataGridPro } from "@mui/x-data-grid-pro";
import { type CommandCenterGridRow } from "src/types/work-packets";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";
import { attempt } from "src/utils/attempt";

const renderCurrencyCell = (params: GridRenderCellParams<any, string>) =>
  attempt(
    () =>
      params.value ? Number.parseFloat(params.value).toLocaleString("en", { style: "currency", currency: "USD" }) : "",
    params.value,
    "Error parsing currency or rendering currency cell",
  );

const baseColumns: GridColDef[] = [
  {
    field: "openCount",
    headerName: "Open Count",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
  },
  {
    field: "openAmount",
    headerName: "Open Amount",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
    renderCell: renderCurrencyCell,
  },
  {
    field: "redFlags",
    headerName: "Red Flags",
    flex: 1,
    type: "number",
    align: "left",
    headerAlign: "left",
  },
];

const workPacketTypeColumnMap: Record<WorkPacketType, GridColDef[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    ...baseColumns,
    {
      field: "expiredCount",
      headerName: "Expired Count",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "expiredAmount",
      headerName: "Expired Amount",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: renderCurrencyCell,
    },
  ],

  [WorkPacketType.SHORTAGES]: baseColumns,
};

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
  row.hierarchy;

const CommandCenter: FC = () => {
  const [rows, setRows] = useState<CommandCenterGridRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentWorkPacketType } = useWorkPacketsContext();

  const fetchCommandCenterData = useCallback(
    debounce(
      async (workPacketType: WorkPacketType) => {
        setLoading(true);
        try {
          const data = await WorkPacketsAPI.fetchCommandCenterData(workPacketType);
          setRows(data);
        } catch (error) {
          console.error("Error in fetchWorkPacketDetails:", error);
        } finally {
          setLoading(false);
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    [],
  );

  useEffect(() => {
    fetchCommandCenterData(currentWorkPacketType);
  }, [fetchCommandCenterData, currentWorkPacketType]);

  return (
    <>
      <Box padding={3}>
        <Typography variant="h6" marginBottom={2}>
          Command Center
        </Typography>
        <Box sx={{ mt: 2, overflow: "auto" }}>
          <Box bgcolor="#FFF" minWidth={800}>
            <DataGridPro
              loading={loading}
              treeData
              rows={rows}
              columns={workPacketTypeColumnMap[currentWorkPacketType]}
              getTreeDataPath={getTreeDataPath}
              groupingColDef={{
                flex: 2,
                headerName: "Recovery stream",
              }}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
            />
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default CommandCenter;
